'use client';
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import { AU, CN, EG, IN, MY, NZ, ZA } from 'country-flag-icons/react/3x2';

type Props = {};

const ShippingLeadsRoller = (props: Props) => {
    return (
        <Swiper
            direction={'vertical'}
            slidesPerView={4}
            loop={true}
            autoplay={true}
            modules={[Autoplay]}
            className='h-[224px] pointer-events-none'
            simulateTouch={false}
        >
            <SwiperSlide>
                <div className='flex justify-between items-center px-2.5 hover:bg-primary-50 rounded-md h-14'>
                    <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-2'>
                            <MY height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Malacca</span>
                        </div>
                        <FaArrowRight size={12} />
                        <div className='flex items-center gap-2'>
                            <EG height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Cairo</span>
                        </div>
                    </div>
                    <span className='text-xs text-primary font-medium'>22 hours</span>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className='flex justify-between items-center px-2.5 hover:bg-primary-50 rounded-md h-14'>
                    <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-2'>
                            <NZ height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Wellington</span>
                        </div>
                        <FaArrowRight size={12} />
                        <div className='flex items-center gap-2'>
                            <AU height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Brisbane</span>
                        </div>
                    </div>
                    <span className='text-xs text-primary font-medium'>22 hours</span>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className='flex justify-between items-center px-2.5 hover:bg-primary-50 rounded-md h-14'>
                    <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-2'>
                            <CN height={18} />
                            <span className='text-sm font-semibold text-gray-800'>China</span>
                        </div>
                        <FaArrowRight size={12} />
                        <div className='flex items-center gap-2'>
                            <ZA height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Johannesburg</span>
                        </div>
                    </div>
                    <span className='text-xs text-primary font-medium'>22 hours</span>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className='flex justify-between items-center px-2.5 hover:bg-primary-50 rounded-md h-14'>
                    <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-2'>
                            <CN height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Shenzhen</span>
                        </div>
                        <FaArrowRight size={12} />
                        <div className='flex items-center gap-2'>
                            <IN height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Delhi</span>
                        </div>
                    </div>
                    <span className='text-xs text-primary font-medium'>22 hours</span>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className='flex justify-between items-center px-2.5 hover:bg-primary-50 rounded-md h-14'>
                    <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-2'>
                            <IN height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Mundra</span>
                        </div>
                        <FaArrowRight size={12} />
                        <div className='flex items-center gap-2'>
                            <ZA height={18} />
                            <span className='text-sm font-semibold text-gray-800'>Durban</span>
                        </div>
                    </div>
                    <span className='text-xs text-primary font-medium'>22 hours</span>
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default ShippingLeadsRoller;
